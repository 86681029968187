
body{
  margin: 0px;
  padding: 0px;
  text-align: center;

  /* fuentes */
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header{
  position: absolute;
  top: 0px;
  z-index: 50;
  height: 100px;
  width: 100%;
  color: white;

  /* background: white; */
  /* border-top: 4px solid rgb(0, 69, 116) ; */
}

#header-out{
  height: 100px;
  width: 100%;
  color: black;
  background: white;
}

.center{
  width: 100%;
  margin: 0px auto;
}

.clearfix{
  clear: both;
  float: none;
}

#logo{
  margin-left: 1rem;
  width: 12rem;
  font-size: 30px;
  float: left;
}


#logo img{
  display: block;
  float: left;
  width: 100%;
}

.hamburger{
  position: absolute;
  top: 2rem;
  right: 1rem;
  font-size: 40px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  cursor: pointer;
}


.close-hamburger{
  color: #000;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 40px;
  opacity: 0;
  visibility: hidden;
  z-index: 50;
}

#menu{
  width: 70%;
  float: right;
  font-size: 18px;
}

.menu-ul{
  line-height: 60px;
  float: right;
  margin-top: 30px;

}

#menu ul li{
  display: block;
  display: inline-block;
  list-style: none;
  margin-left: 20px;
  margin-right: 20px;
}

.LinkColorWhite {
  text-decoration: none;
  color: white;
  transition: all 300ms;
}
.LinkColorBlack {
  text-decoration: none;
  color: black;
  transition: all 300ms;
}
#menu a:hover, .active, .button-idioma:hover, .idioma:hover{
  color: rgb(94, 224, 224) !important;
  text-shadow: #111131;
}

.idioma{
  cursor: pointer;
}

.content-buttons-idioma{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 7rem;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  right: 1rem;
}
.button-idioma{
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-top: 5px;

  border: none;
  padding: 0.5rem 0 0.5rem 0rem;
  font-size: 18px;
  color: #000000;
}

.show-idiomas{
  opacity: 1;
  visibility: visible;
  z-index: 1;
}


/* home image*/

.section-1{
  height: 100vh;
  width: 100%;
   height: 600px;
   background: rgb(20, 20, 20);
}
.lazyloaded{
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%);
  -webkit-filter: brightness(60%);
}


.loaded-phrase{
  position: absolute;
  top: 3rem;
  left: 3rem;
  text-align: left;
  width: 60%;
}

.title {
font-size: 5rem;
color: white;
text-shadow: 4px 4px 4px rgb(54, 50, 50);
line-height: 1.2;
}

/* tarjetas servicios */

.title-service{
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.line{
  width: 5rem;
  height: 5px;
  background: #f3e304;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.cards-service {
  margin: 1rem;
  margin-top: 2rem;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
}
.card-service {
  margin: 10px;
  margin-bottom: 2rem;
  width: 300px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.image-service img {
  width: 100%;
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}
.card-service .content {
   padding: 1rem;
}

.card-service .content h2{
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.content p{
  font-size: 15px;
}

/* about us */
.about{
  display: inline-block;
  font-family: Roboto Condensed,sans-serif;
  width: 100%;
}

.mision{
  width: 50%;
  float: left;

}

.mision-center{
  width: 80%;
  margin: 0px auto;
  text-align: left;
}

.con{
  display: inline-block;
}

.t-m{
  margin-top: 3rem;
  font-size: 1.1rem;
  text-transform:uppercase;
  float: left;
}
.line-about {
  width: 5rem;
  height: 3px;
  background: #f3cf04;
  margin: auto;
  margin-bottom: 1rem;
  float: left;
  margin-top: 4.5rem;
  margin-left: 1rem;
}

.title-mision{
  font-size: 3rem;
  color: #20201e;
  margin-top: 1rem;
  text-transform:uppercase;
}

.tex-mision {
  line-height: 2;
}

.image-mision{
  width: 50%;
  height: 500px;
  float: left;
}

.image-mision img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* contactanos */
.content-contact{
  width: 80%;
  display: inline-block;
}

.container-contact {
  max-width: 510px;
  margin: 1rem 4rem;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-bottom: 3px solid #ccc;
  float: left;
}
.row {
  margin: 0 0 1em 0;
}
.header {
  padding: 1.5em 2.5em;
  border-bottom: 1px solid #ccc;
  background: url(../images/contact.jpg) bottom;
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir completamente el fondo */
  color: #fff;
  text-shadow: 4px 4px 4px rgb(54, 50, 50);
}
.body {
  padding: .5em 2.5em 1em;
}
.pull-right {
  float: right;
}
.contact-title {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  display: inline-block;
  font-weight: 100;
  font-size: 2.8125em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 0 0.1em 0;
  padding: 0 0 0.4em 0;
}
.contact-subtitle {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1.25em;
  margin: 1em 0 0.4em 0;
}
.btn {
  font-size: 1.0625em;
  display: inline-block;
  padding: 0.74em 1.5em;
  margin: 1.5em 0 0;
  color: #120453;
  text-transform: uppercase;
  background-color: #fffefe;
  border: 3px solid;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  transition: 300ms all;
  cursor: pointer;
}
.btn:hover {
  color: #ffc014;
}

.form-contact {
  max-width: 100%;
  display: block;
}
.form-contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.form-contact ul li {
  margin: 0 0 0.25em 0;
  clear: both;
  display: inline-block;
  width: 100%;
}
.form-contact ul li:last-child {
  margin: 0;
}
.form-contact ul li p {
  margin: 0;
  padding: 0;
  float: left;
}
.right {
  float: right;
}
.divider {
  margin: 0.5em 0 0.5em 0;
  border: 0;
  height: 1px;
  width: 100%;
  display: block;
  background-color: #4f6fad;
  background-image: linear-gradient(to right, #f5d625, #1a54c9);
}
 .req {
  color: #f5f10b;
}
.form-contact label {
  display: block;
  margin: 0 0 0.5em 0;
  color: #4f6fad;
  font-size: 1em;
}
.data-contact {
  margin: 0 0 0.5em 0;
  border: 1px solid #ccc;
  padding: 6px 10px;
  color: #555;
  font-size: 1em;
  width: 70%;
}
.form-contact textarea {
  border: 1px solid #ccc;
  padding: 6px 10px;
  width: 100%;
  color: #555;
  min-width: 90%;
  max-width: 90%;
  min-height: 100px;
  max-height: 400px;
  font-size: 1rem;
  font-family: 'Segoe UI', sans-serif;
}
/* contactos a las derecha, ubicacion telefono email */

.direct-contact-container {
  width: 40%;
  float: left;
  text-align: left;
}


/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  padding-right: 20px;
  margin-top: 5rem;
}

.list-item {
  line-height: 4;
  color: #494949;
  width: 100%;
}

.list-item i{
  font-size: 2rem;
  position: relative;
  top: 7px;

}

.contact-text {
  font: 300 18px 'Lato', sans-serif;
  letter-spacing: 1.9px;
  color: #383838;
}
.city{
  display: inline-block;
  width: 100%;
}

.place {
  line-height: 1.5;
  float: left;
}

.space {
  margin-left: 40px;
}


/* comentario o testimonios */
.outerdiv
{
	  width: 100%;
    min-height: 100vh;
    background: #EDF2F8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.innerdiv
{
	transform: scale(0.9);
	margin: 1rem;
	display: grid;
	grid-gap: 1.5rem;
	grid-template-rows: repeat(2,22rem);
	/* grid-template-columns: repeat(4,17rem); */
}
.eachdiv
{
	padding: 1rem 2rem;
    border-radius: 0.8rem;
    box-shadow: 5px 5px 20px #6d6b6b6b;
    color: white;
}
.div1
{
	background: #201f48;
    grid-column: 1/3;
    grid-row: 1/2;
    /* background-image: url(https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/bg-pattern-quotation.svg); */
    background-repeat: no-repeat;
    background-position-x: 25rem;
}
.div2
{
	background:#49556B;
    grid-column: 3/4;
    grid-row: 1/2;
}
.div3
{
	background: rgb(123, 90, 214);
    grid-column: 4/5;
    grid-row: 1/3;
    color: black;
}
.div4
{
	background: #cbcbcd;
	grid-column: 1/2;
    grid-row: 2/3;
    color: black;
}
.div5
{
	background: #18202D;
	grid-column: 2/4;
    grid-row: 2/3;
}
.userdetails
{
	display: flex;
}

.detbox
{
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.detbox p
{
	margin: 0;
}
.detbox .name
{
	color: hsl(0, 0%, 81%);
    font-size: 0.9rem;
    margin-bottom: 0.1rem;
    font-weight: 600;
}
.detbox .name.dark
{
	color: #49505A;
}
.detbox .designation
{
	color: hsl(0, 0%, 81%);
    opacity: 50%;
    font-size: 0.8rem;
}
.detbox .designation.dark
{
	color: #49505A;
}
.review h4
{
	font-size: 1.4rem;
	color: #F3DEFF;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.8rem;
}
.review.dark h4{
	color:#4B5258;
}
.review p
{
	font-size: 0.95rem;
    color: #F3DEFF;
    font-weight: 500;
    opacity: 50%;
    line-height: 1.5;
}
.review.dark p{
	color: #0e0e0e;
}
.attribution
{
	font-size: 1rem;
    line-height: 1.5;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    text-align: right;
}
.attribution a
{
	text-decoration: none;
}

@media only screen and (max-width: 1000px)
{
	.innerdiv
	{
		transform: scale(0.7);
	}
}
@media only screen and (max-width: 800px)
{
	.innerdiv
	{
		transform: scale(0.6);
	}
}
@media only screen and (max-width: 600px)
{
	.div1 {
		background-position-x: 10rem;
	}
	.innerdiv
	{
		display: flex; 
		flex-direction: column;
		transform: scale(1);
		margin: 2rem;
		margin-bottom: 5rem;
	}
}



/* trabajos, portafolio
.credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: inherit;
} */
 .options {
  display: flex;
  margin: auto;
  margin-bottom: 3rem;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  min-width: 600px;
  max-width: 85%;
  width: calc(100% - 100px);
  height: 400px;
}
@media screen and (max-width: 718px) {
   .options {
    min-width: 520px;
  }
   .options .option:nth-child(5) {
    display: none;
  }
}
@media screen and (max-width: 638px) {
   .options {
    min-width: 440px;
  }
   .options .option:nth-child(4) {
    display: none;
  }
}
@media screen and (max-width: 558px) {
   .options {
    min-width: 360px;
  }
   .options .option:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 478px) {
   .options {
    min-width: 280px;
  }
   .options .option:nth-child(2) {
    display: none;
  }
}
 .options .option {
  position: relative;
  overflow: hidden;
  min-width: 60px;
  margin: 10px;
  background: var(--optionBackground, var(--defaultBackground, #E6E9ED));
  background-size: auto 120%;
  background-position: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
 .options .option:nth-child(1) {
  --defaultBackground:#ED5565;
}
 .options .option:nth-child(2) {
  --defaultBackground:#FC6E51;
}
 .options .option:nth-child(3) {
  --defaultBackground:#FFCE54;
}
 .options .option:nth-child(4) {
  --defaultBackground:#2ECC71;
}
 .options .option:nth-child(5) {
  --defaultBackground:#5D9CEC;
}
 .options .option:nth-child(6) {
  --defaultBackground:#AC92EC;
}
 .options .option.active {
  flex-grow: 10000;
  transform: scale(1);
  max-width: 80rem;
  margin: 0px;
  border-radius: 40px;
  background-size: auto 100%;
  /*&:active {
     transform:scale(0.9);
  }*/
}
 .options .option.active .shadow {
  box-shadow: inset 0 -120px 120px -120px black, inset 0 -120px 120px -100px black;
}
 .options .option.active .label {
  bottom: 20px;
  left: 20px;
}
 .options .option.active .label .info > div {
  left: 0px;
  opacity: 1;
}
 .options .option:not(.active) {
  flex-grow: 1;
  border-radius: 30px;
}
 .options .option:not(.active) .shadow {
  bottom: -40px;
  box-shadow: inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
}
 .options .option:not(.active) .label {
  bottom: 10px;
  left: 10px;
}
 .options .option:not(.active) .label .info > div {
  left: 20px;
  opacity: 0;
}
.options .option .shadow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 120px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
.options .option .label {
  display: flex;
  position: absolute;
  right: 0px;
  height: 40px;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
}
.options .option .label .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  color: var(--defaultBackground);
}
.options .option .label .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  color: white;
  white-space: pre;
}
.options .option .label .info > div {
  position: relative;
  transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
}
.options .option .label .info .main {
  font-weight: bold;
  font-size: 1.2rem;
}
.options .option .label .info .sub {
  transition-delay: 0.1s;
}






/* footer */
.footer {
  display: flex;
  justify-content: space-around;
  background-color: #111131;
  color: rgb(191, 189, 189);
  padding: 20px 4rem;
}

.footer div {
  flex: 1;
  text-align: center;
}

.footer h3{
  color: #e6e6e6;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  /* margin-left: 10%; */
  justify-content: center;
  text-align: center;
  position: relative;
}
.footer h3::before {
  content: "";
  position: absolute;
  left: 35%;
  bottom: -12px;
  height: 2px;
  width: 50px;
  background: #f7ff14;
}

.des-contact p{
  margin: 1rem 0 1rem 20% ;
  text-align: left;
}


.footer h3 {
  margin-bottom: 2rem;
}

 .footer ul {
  margin: 1rem 0 0 0 ;
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin: 1rem 0;
}

.footer ul li a {
  color: rgb(191, 189, 189);
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer-social ul li a {
  font-size: 24px;
  margin: 0 10px;
}

.footer-social ul li a:hover {
  color: #ddd;
}

.copyright-area{
  display: flex;
  height: 2rem;
  align-items: center;
  background-color: #090028;
  color: white;
  padding: 10px 1rem;
  font-size: 0.8rem;
  
}
.copyright-area div{
  flex: 1;
}
.copyright-text{
  text-align: left;
}
.elaborado{
  text-align: right;
}

.svg-inline--fa {
  vertical-align: -0.200em;
}

.rounded-social-buttons {
  text-align: center;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover, .rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter, .fa-facebook-f, .fa-linkedin, .fa-tiktok, .fa-youtube, .fa-instagram {
  font-size: 25px;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover, .rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.whatsapp {
  background: #128814;
}

.rounded-social-buttons .social-button.whatsapp:hover, .rounded-social-buttons .social-button.whatsapp:focus {
  color: #128824;
  background: #fefefe;
  border-color: #128822;
}

.rounded-social-buttons .social-button.instagram {
  background: #88125f;
}

.rounded-social-buttons .social-button.instagram:hover, .rounded-social-buttons .social-button.instagram:focus {
  color: #841288;
  background: #fefefe;
  border-color: #881288;
}


/* error page */

#main{
  display: table;
  width: 100%;
  height: 75vh;
  text-align: center;
}

.fof{
  display: table-cell;
  vertical-align: middle;
}

.fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
  color: #3f3f3c;
}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #888;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}


/* responsive */

@media (min-width : 1370px){
  /* about */
  .about{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .mision-center {
    width: 60%;
  }

  .image-mision {
    height: 600px;
  }
}



@media (max-width :  1340px) {
  /* contactanos */
  .content {
    width: 90%;
  }
}

@media (max-width :  1300px) {
  /* servicios */
  .cards-service {
    grid-template-columns: repeat(3, 1fr);
  }

  /* menu */
  .content-buttons-idioma {
    width: 10rem;
    right: 5rem;
  }
}

@media (max-width :  1190px) {
  /* contactanos */
  .content-contact {
    width: 100%;
  }

    /* menu */
    .content-buttons-idioma {
      right: 4rem;
    }
}

@media (max-width :  1130px) {

  /* portada home */
  .title {
    font-size: 5rem;
  }
  .section-1 {
    height: 500px;
  }
}
@media (max-width :  1080px) {
    /* menu */
    .content-buttons-idioma {
      width: 11rem;
      right: 2rem;
    }
}


@media (max-width :  1070px) {
  /* contactanos */
  .container-contact {
    margin: 1rem 3rem;
  }
}

@media (max-width :  1050px) {
  /* about us */
  .title-mision {
    font-size: 2rem;
  }

  .t-m {
    margin-top: 3rem;
    font-size: 1rem;
  }

  /* contactanos */
  .contact-list {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width :  1014px) {
  /* contactanos */
  .contact-text {
    font: 300 17px 'Lato', sans-serif;
  }

    .container-contact {
      margin: 1rem auto;
      float: none;
    }
  
    .direct-contact-container {
      float: none;
      margin: auto;
    }
  
    .contact-list {
      margin-top: 2rem;
    }
  
    .list-item {
      line-height: 3;
    }

    /* menu */
    .menu-ul {
      width: 70%;
    }
}

@media (max-width :  1000px) {
  /* pie de pagina */
  .contact {
  width: 65%;
  }
}

@media (max-width :  980px) {
  /* servicios */
   .cards-service {
    grid-template-columns: repeat(2, 1fr);
  }

}



@media (max-width :  940px) {

  /* portada home */
.title {
  font-size: 4rem;
}
  .section-1 {
    height: 450px;
  }
}

@media (max-width :  920px) {

  /* menu */
  #menu {
  width: 60%;
  }

  #menu ul {
    width: 100%;
  }
}


@media (max-width :  860px) {
  .direct-contact-container {
    width: 50%;
  }
}

@media (max-width :  830px) {
   /* portada home */
  .section-1 {
    height: 400px;
  }

  /* about us */
  .line-about {
    margin-top: 3.5rem;
  }

  .t-m {
    margin-top: 2rem;
  }
}

@media (max-width :  780px) {

  /* about us */
  .mision {
    width: 100%;
    float: none;
  }
  .image-mision {
    width: 100%;
    height: 400px;
    float: none;
  }
}

@media (max-width :  760px) {
    /* portada home */
  .title {
    font-size: 3.5rem;
  }
  .loaded-phrase {
    top: 4rem;
  }
    .section-1 {
    height: 350px;
  }

  .footer {
    flex-direction: column;
    padding: 20px 2rem;
  }
}

@media (max-width :  740px) {

  /* pie de pagina */
  .cont{
    float: none;
  }
  .m{
    margin-left: 0px;
    margin-top: 5px;
  }
}

@media (max-width :  730px) {
    /* menu */
    .content-buttons-idioma {
      right: 1rem;
    }
}

@media (max-width : 710px){
  /* menu */
  #header{
    position: relative;
  background: white;
    color: black;
  }
  .hamburger{
    opacity: 1;
    visibility: visible;
  }
  .hamburger-hidden{
    opacity: 0;
    visibility: hidden;
  }

  .close-hamburger-show{
    opacity: 1;
    visibility: visible;
  }
  .LinkColorWhite {
    color: black;
  }

  .center {
    width: 100%;
  }
  #logo {
    margin-left: 2rem;
  }
  .menu-ul {
  background: white;
  position: absolute;
  top: -30rem;
  right: 0rem;
  z-index: 0;
  visibility: inherit;
  opacity: 0;
  transition: all 300ms;
  margin-top: 0px;
  padding: 10px 0 10px 0;
}

.turn-on-menu{
  visibility: visible;
  opacity: 1;
  z-index: 1;
  top: 0rem;
}

  #menu ul li {
    display: block;
  }

  #menu a {
    padding: 1rem 5rem 1rem 5rem;
  }
  
    /* menu */
    .content-buttons-idioma {
      width: 12rem;
      right: 50%;
      left: 40%;
    }
}

@media (max-width :  690px) {

  /* contactanos */
  .direct-contact-container {
    width: 60%;
  }
}

@media (max-width :  670px) {
  
  
  /* portada home */
  .title {
    font-size: 3rem;
  }

    .section-1 {
    height: 300px;
  }

}

@media (max-width :  660px) {
  /* servicios */
     .cards-service {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width :  570px) {
    /* portada home */
    .title {
      font-size: 2.5rem;
    }

    .loaded-phrase {
      top: 5rem;
    }
  
      .section-1 {
      height: 250px;
    }

    /* contactos */
    .direct-contact-container {
      width: 80%;
    }

    .container-contact {
      max-width: 90%;
    }
    
}

@media (max-width : 520px){

  /* contactanos */
  .header {
    padding: 1em 2.5em;
  }
  .body {
    padding: 1em 1em 1em;
  }

  .contact-title {
    font-size: 2.3em;
    margin: 0;
  }
  .contact-subtitle {
    font-size: 1.1em;
  }

  .data-contact {
    width: 80%;
  }
}

@media (max-width :  500px) {

  /* about us */
  .image-mision {
    height: 300px;
  }
}

@media (max-width :  470px) {
  /* portada home */
  .title {
    font-size: 2rem;
  }

    .section-1 {
    height: 200px;
  }

  /* about us */
  .mision-center {
    width: 90%;
  }
}

@media (max-width :  470px) {

  /* menu */
  .content-buttons-idioma {
    left: 35%;
  }
}

@media (max-width :  430px) {
  /* contactanos */
  .gmail {
    margin-left: 20px;
  }
  .place {
    margin-left: 20px;
  }
  .phone {
    margin-left: 20px;
  }
}


@media (max-width :  400px) {

  /* contactanos */
  .direct-contact-container {
    width: 95%;
  }
  .contact-subtitle {
    font-size: 1em;
  }
  .contact-title {
    font-size: 2em;
  }
}

@media (max-width :  380px) {
  /* portada home */
  .title {
    font-size: 1.8rem;
  }
  .loaded-phrase {
    top: 6rem;
  }

    .section-1 {
    height: 180px;
  }

    /* menu */

    .content-buttons-idioma {
      left: 30%;
    }

    /* footer */
    .des-contact p{
      margin: 0.5rem 0 0.5rem 10% ;
    }
    
}

@media (max-width :  366px) {
  .contact-subtitle {
    font-size: 0.9em;
  }
}